const atendimentos =
  [
    {
      responsavel: 'Eduardo',
      email: 'marcosdetetive.eduardo@gmail.com',
      whatsapp: [
        { cidade: "São Paulo", contato: '+5511933215168', principal: true, mensagem: null},
      ],
      // endereco: [
      //   { address: "Rua Augusta, 101 Conjuntos 1017 e 1018", city: "São Paulo - SP", cep: "01305-000"}
      // ],
      endereco: null,
      regiao: [
        { uf: 'SP', uf_name: 'São Paulo' }
      ],
    },
    {
      responsavel: 'Eduardo',
      email: 'marcosdetetive.eduardo@gmail.com',
      whatsapp: [
        { cidade: "São Paulo", contato: '+5511996651121', principal: true, mensagem: null},
      ],
      // endereco: [
      //   { address: "Rua Augusta, 101 Conjuntos 1017 e 1018", city: "São Paulo - SP", cep: "01305-000"}
      // ],
      endereco: null,
      regiao: [
        { uf: 'SP2', uf_name: 'São Paulo' }
      ],
    },
    {
      responsavel: "Dolguas",
      email: "detetivemarcos.dog@gmail.com",
      whatsapp: [
        { cidade: "Cliente do exterior", contato: '+16308668055', principal: true, mensagem: null },
      ],
      endereco: null,
      regiao: [
        { uf: 'MARCOS', uf_name: 'Exterior' }
      ],
    },
    {
      responsavel: "karan",
      email: "marcosdetetive.karan@gmail.com",
      whatsapp: [
        { cidade: "Contato 1", contato: '+558005917732', principal: true, mensagem: 'Atendimento em todo Brasil'},
        { cidade: "Contato 2", contato: '+5592993528978', principal: false},

      ],
      endereco: null,
      regiao: [
        { uf: 'BA', uf_name: 'Bahia' },
        { uf: 'RO', uf_name: 'Rondônia' },
        { uf: 'AC', uf_name: 'Acre' },
        { uf: 'PA', uf_name: 'Pará' },
        { uf: 'RR', uf_name: 'Roraima' },
        { uf: 'AM', uf_name: 'Amazonas' },
        { uf: 'AP', uf_name: 'Amapá' },
        { uf: 'MT', uf_name: 'Mato Grosso' },
        { uf: 'TO', uf_name: 'Tocantins' },
        { uf: 'MA', uf_name: 'Maranhão' },
        { uf: 'SE', uf_name: 'Sergipe' },
        { uf: 'PI', uf_name: 'Piauí' },
        { uf: 'CE', uf_name: 'Ceará' },
        { uf: 'AL', uf_name: 'Alagoas' },
        { uf: 'PB', uf_name: 'Paraíba' },
        { uf: 'PE', uf_name: 'Pernambuco' },
        { uf: 'RN', uf_name: 'Rio Grande do Norte' }
      ]
    },
    {
      responsavel: "Dolguas",
      email: "detetivemarcos.dog@gmail.com",
      whatsapp: [
        { cidade: "Contato", contato: '+5541991321807', principal: true, mensagem: 'Atendimento em todo Brasil ' },
      ],
      endereco: [
        { address: "Av. Pref. Osmar Cunha, 416, Sala 1108", city: "Florianópolis - SCP", cep: "88015-100"}
      ],

      regiao: [
        { uf: 'SC', uf_name: 'Santa Catarina' },
      ]
    },
    {
      responsavel: "Dolguas",
      email: "detetivemarcos.dog@gmail.com",
      whatsapp: [
        { cidade: "Contato 2", contato: '+558006554848', principal: true, mensagem: 'Atendimento em todo Brasil ' },
        // { cidade: "Contato 2", contato: '+5561996916556', principal: true, mensagem: 'Atendimento em todo Brasil ' },
        // { cidade: "Contato 1", contato: '+5551997351676', principal: false, mensagem: null },
      ],
      endereco: null,
      regiao: [
        { uf: 'RS', uf_name: 'Rio Grande do Sul' },
        { uf: 'PR', uf_name: 'Paraná' },
        { uf: 'MG', uf_name: 'Minas Gerais' },
        { uf: 'RJ', uf_name: 'Rio de Janeiro' },
        { uf: 'ES', uf_name: 'Espírito Santo' },
        { uf: 'MS', uf_name: 'Mato Grosso do Sul' },
        { uf: 'GO', uf_name: 'Goiás' },
        { uf: 'DF', uf_name: 'Distrito Federal' },
      ]
    },
    {
      responsavel: "Dolguas",
      email: "detetivemarcos.dog@gmail.com",
      whatsapp: [
        { cidade: "Contato", contato: '+558001912371', principal: true, mensagem: 'Atendimento em todo Brasil' },
        // { cidade: "Contato", contato: '+558005911259', principal: true, mensagem: 'Atendimento em todo Brasil' },
      ],
      endereco: null,
      regiao: [
        { uf: 'PC', uf_name: 'Desktop' },
      ]
    }
  ]

export default {

  renderRegiaoMail(uf) {
    if (!!uf) {
      var result = {}

      atendimentos.map(value => {
        const regiao = value.regiao.filter(region => region.uf === uf.toUpperCase())
        if (regiao.length === 1) {
          result =
          {
            email: value.email,
            whatsapp: value.whatsapp,
          }
          return
        }
      })
      return result
    }
  },

  renderRegiao(uf) {
    if (!!uf) {
      var whatsapp = []
      
      atendimentos.map(value => {
        const regiao = value.regiao.filter(region => region.uf === uf.toUpperCase())
        if (regiao.length === 1) {
          whatsapp = value.whatsapp
          return
        }
      })
      return whatsapp
    }
  },

  renderAddress(uf) {
    if (!!uf) {
      var address = []

      atendimentos.map(value => {
        const endereco = value.regiao.filter(region => region.uf === uf.toUpperCase())
        if(endereco.length === 1){
          address = value.endereco
          return
        }
      })
      return address
    }
  
  }
}